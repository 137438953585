<template>
  <div style="padding: 10px">
    <!--    功能区域-->
    <div style="margin: 10px 0">
      <!-- <el-button type="primary" @click="add">新增</el-button> -->
    </div>

    <!--    搜索区域-->
    <div style="margin: 10px 0">
      <el-input v-model="search" placeholder="请输入关键字" style="width: 20%" clearable></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button>
    </div>
    <el-table v-loading="loading" :data="tableData" border stripe style="width: 100%">
      <el-table-column prop="id" label="ID" sortable>
      </el-table-column>
      <el-table-column prop="userId" label="提现用户">
      </el-table-column>
      <el-table-column prop="extractPrice" label="提现金额">
      </el-table-column>
      <!-- <el-table-column
          prop="balance"
          label="账户余额">
      </el-table-column> -->
      <el-table-column prop="alipayCode" label="提现账号">
      </el-table-column>
      <el-table-column prop="realName" label="真实姓名">
      </el-table-column>
      <!-- <el-table-column prop="extractType" label="提现方式">
      </el-table-column> -->
      <el-table-column label="提现方式">
        <template #default="scope">
          <span v-if="scope.row.extractType === 1" style="color: rgb(68, 75, 68)">支付宝</span>
          <span v-if="scope.row.extractType === 2" style="color: rgb(68, 75, 68)">银行卡</span>
        </template>
      </el-table-column>
      <el-table-column label="提现状态">
        <template #default="scope">
          <span v-if="scope.row.status === -1" style="color: rgb(255, 0, 0)">未通过</span>
          <span v-if="scope.row.status === 0" style="color: rgb(68, 75, 68)">审核中</span>
          <span v-if="scope.row.status === 1" style="color: green">已审核</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
          prop="failMsg"
          label="失败原因">
      </el-table-column> -->
      <el-table-column prop="createdAt" label="时间">
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button v-if="scope.row.status === 0" size="mini" @click="handleEdit(scope.row)">审核</el-button>
          <!-- <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button> -->
          <!-- <el-popconfirm title="确定删除吗？" @confirm="handleDelete(scope.row.id)">
            <template #reference>
              <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>

    <div style="margin: 10px 0">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[5, 10, 20]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>

    <el-dialog title="提示" v-model="dialogVisible" width="50%">
      <el-form :model="form" label-width="120px">
        <el-form-item label="标题">
          <el-input v-model="form.title" style="width: 50%"></el-input>
        </el-form-item>

        <div id="div1"></div>
        <!--        <el-form-item label="内容">-->
        <!--          <el-input v-model="form.price" style="width: 80%"></el-input>-->
        <!--        </el-form-item>-->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="save">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="审核" v-model="dialogVisible" width="50%">
      <el-radio v-model="form.radio" label="1">审核通过</el-radio>
      <el-radio v-model="form.radio" label="2">审核不通过</el-radio>

      <el-form :model="form" label-width="120px">
        <el-form-item label="备注">
          <el-input v-model="form.remark" style="width: 80%"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
          </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>

import E from 'wangeditor'
import request from "@/utils/request";

let editor;

export default {
  name: 'News',
  components: {},
  data() {
    return {
      loading: true,
      form: {},
      dialogVisible: false,
      search: '',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      vis: false,
      detail: {},
      radio: '1',
    }
  },
  created() {
    this.load()
  },
  methods: {
    details(row) {
      this.detail = row
      this.dialogVisible = true
    },
    filesUploadSuccess(res) {
      console.log(res)
      this.form.cover = res.data
    },
    load() {
      this.loading = true
      request.get("/withdrawal", {
        params: {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          search: this.search
        }
      }).then(res => {
        this.loading = false
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    add() {
      this.dialogVisible = true
      this.form = {}

      this.$nextTick(() => {
        // 关联弹窗里面的div，new一个 editor对象
        if (!editor) {
          editor = new E('#div1')

          // 配置 server 接口地址
          editor.config.uploadImgServer = 'http://' + window.server.filesUploadUrl + ':9090/files/editor/upload'
          editor.config.uploadFileName = "file"  // 设置上传参数名称
          editor.create()
        }

        editor.txt.html("")

      })

    },
    save() {
      //this.form.content = editor.txt.html()  // 获取 编辑器里面的值，然后赋予到实体当中

      if (this.form.id) {  // 更新
        request.put("/withdrawal", this.form).then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "更新成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.load() // 刷新表格的数据
          this.dialogVisible = false  // 关闭弹窗
        })
      }

    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true

      // this.$nextTick(() => {
      //   // 关联弹窗里面的div，new一个 editor对象
      //   // 关联弹窗里面的div，new一个 editor对象
      //   if (!editor) {
      //     editor = new E('#div1')

      //     // 配置 server 接口地址
      //     editor.config.uploadImgServer = 'http://localhost:9090/files/editor/upload'
      //     editor.config.uploadFileName = "file"  // 设置上传参数名称
      //     editor.create()
      //   }

      //   editor.txt.html(row.content)
      // })
    },
    handleDelete(id) {
      console.log(id)
      request.delete("/news/" + id).then(res => {
        if (res.code === '0') {
          this.$message({
            type: "success",
            message: "删除成功"
          })
        } else {
          this.$message({
            type: "error",
            message: res.msg
          })
        }
        this.load()  // 删除之后重新加载表格的数据
      })
    },
    handleSizeChange(pageSize) {   // 改变当前每页的个数触发
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {  // 改变当前页码触发
      this.currentPage = pageNum
      this.load()
    }
  }
}
</script>
